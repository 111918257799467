import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import SecondaryNav from '../components/secondarynav'
import BorderedBox from '../components/borderedbox'
import TitleHeader from '../components/titleheader'
import Card from '../components/card'

// Images

import corporateimage3 from '../assets/images/governance-lp/governance_policies_box_d.png'
import corporateimage4 from '../assets/images/governance-lp/current_bod_may_2023_updated.jpg'
import esgPdf2022 from '../assets/images/TCP-2022-ESG-Report-Final.pdf'
import esgPdf2021 from '../assets/images/esgReport2020.pdf'
import internalesgdk from '../assets/images/esg_2021/esg_lp_header_dk.jpg'
import internalesgmb from '../assets/images/esg_2021/esg_lp_header_mobile.jpg'
import pressdk from '../assets/images/esg_2021/dk-press.png'
import pressmb from '../assets/images/esg_2021/mb-press.png'

import { trackPageData, getPageDetails } from '../analytics'

class ResponsibleSourcing extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Governance | The Children's Place Corporate Website"
          description="The Children's Place is committed to responsible sourcing practices in our supply chain. Learn more on The Children's Place Corproate Website."
        />
        <div
          style={{
            position: `relative`,
          }}
          class="row"
        >
          <a href={`${esgPdf2022}`} target="_blank">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <TitleHeader
                image={internalesgdk}
                mbheaderimage={internalesgmb}
                title="ENVIRONMENT, SOCIAL &amp; GOVERNANCE"
                addClass="corporateTitle esgTitle"
              />
            </div>
          </a>
          <br />

          <a
            class="esg-postion-lb"
            href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2021-annual-esg-report/`}
            target="_blank"
          >
            <div class="col-12">
              <img src={pressdk} className="img-fluid hidden-xs" alt="" />
              <img src={pressmb} className="img-fluid visible-xs" alt="" />
            </div>
          </a>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <SecondaryNav
              active="Governance"
              name1="Environment"
              link1="/environment"
              name2="Social"
              link2="/social"
              name3="Governance"
              link3="/governance"
            />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <BorderedBox>
              <h2>GOVERNANCE AT THE CHILDREN’S PLACE</h2>

              <p>
                {' '}
                Under the guidance of our Board of Directors, The Children’s
                Place implements and maintains governance practices and policies
                that align with market best practices.{' '}
              </p>
              <p>
                For more details, please see{' '}
                <a
                  target="_blank"
                  href="http://investor.childrensplace.com/financial-information/annuals-proxies"
                >
                  our latest proxy statement.
                </a>
              </p>
            </BorderedBox>
          </div>
        </div>

        <div id="sourcinggroup">
          {/* safe working */}
          <div class="row mb-12 text-center">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <Card
                title="BOARD OF DIRECTORS "
                description="Our Board of Directors oversees the business, assets, affairs and performance of our Company."
              >
                <a
                  target="_blank"
                  href="https://corporate.childrensplace.com/board-of-directors/"
                >
                  <p>Learn more about our Board of Directors</p>
                </a>
                {}
              </Card>
            </div>
          </div>

          <br />
          <br />

          {/* education */}
          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6">
              <img
                src={corporateimage3}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>

            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card
                title="PRACTICES &amp; POLICIES "
                description="We have implemented governance practices with respect to our Board of Directors, our management and our shareholders that align with market best practices. Our governance policies guide our Board of Directors, our management and our associates to uphold the highest ethical standards in conducting business on behalf of The Children’s Place."
              >
                <a target="_blank" href={`${esgPdf2022}#page=69`}>
                  <p>Learn more about our governance practices and policies</p>
                </a>
                {/* <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoretwo" aria-expanded="false" aria-controls=""></button> */}
              </Card>
            </div>
          </div>
        </div>
        {/* end environmental */}
      </Layout>
    )
  }
}

export default ResponsibleSourcing
